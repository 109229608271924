// SHARE
(() => {
  // Temp hack - because of crappy native MacOS Web Share API implementation
  const isMobileOrTabletBreakpoint = window.matchMedia('(max-width: 1024px)');
  const hasCoarsePointer = window.matchMedia('(pointer: coarse)');

  // print button
  const printButton = document.querySelector(
    '.tc_share__print:not([data-processed="js"])',
  );

  if (printButton) {
    printButton.setAttribute('data-processed', 'js');
    printButton.addEventListener('click', () => {
      window.print();
    });
  }

  // share button
  document.addEventListener('click', (e) => {
    const shareButton = e.target.closest('.tc_share__button');

    // if share button is clicked
    if (shareButton) {
      const title =
        shareButton.getAttribute('data-target-title') || document.title;
      const url =
        shareButton.getAttribute('data-target-url') || document.location.href;

      // use Web Share API if supported (though not MacOS) and suppress popover
      if (
        navigator.share &&
        isMobileOrTabletBreakpoint.matches &&
        hasCoarsePointer.matches
      ) {
        navigator.share({ title: title, url: url }).catch(console.error);
        e.preventDefault();
      }
    } else {
      const popoverOpen = document.querySelector(
        '.tc_share__popover:popover-open',
      );

      // look for open popover
      if (popoverOpen) {
        const copyLinkInput = popoverOpen.querySelector('input[type="text"]');

        // highlight url in input
        copyLinkInput.select();

        const copyLinkButton = e.target.closest(
          '.tc_share__popover__main__copylink__action',
        );

        // copy url to clipboard if copy button is clicked
        if (copyLinkButton) {
          copyToClipboard(copyLinkInput.value, copyLinkButton);
        }
      }
    }
  });

  const copyToClipboard = async (url, button) => {
    try {
      await navigator.clipboard.writeText(url);
      button.classList.add('tc_button--green');
    } catch (err) {
      button.classList.add('tc_button--tv2red');
    }
  };
})();
